@charset "UTF-8";
/* ==========================================================================
   main

   We're using the BEMIT approach.
   It is a scalable and maintainable css architecture
   - https://itcss.io/ (soon)
   - https://www.creativebloq.com/web-design/manage-large-css-projects-itcss-101517528

   0. vendors
   1. setttings
   2. tools
   3. generic
   4. base
      - base
      - vendors / generated
   5. objects
   6. components
   7. utilities
   ========================================================================== */
/*
  0. vendors
  Global variables (colors, measures, etc)
  ========================================================================== */
/*
    1. settings
    Global variables (colors, measures, etc)
    ========================================================================== */
/* 
 *  BREAKPOINTS 
----------------------------------------------------------------------*/
/*
    * 4. Breakpoints
    ****************************************************************/
/*
    * 5. Media queries
    ****************************************************************/
@import url(https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400);
@import url(https://fonts.googleapis.com/css?family=Inconsolata);
body:after {
  content: "none";
  display: none;
  /* Prevent from displaying. */ }

@media screen and (min-width: 400px) {
  body:after {
    content: "mobiles"; } }

@media screen and (min-width: 550px) {
  body:after {
    content: "mobile"; } }

@media screen and (min-width: 768px) {
  body:after {
    content: "tabletp"; } }

@media screen and (min-width: 980px) {
  body:after {
    content: "tablet"; } }

@media screen and (min-width: 1100px) {
  body:after {
    content: "desktop"; } }

@media screen and (min-width: 1400px) {
  body:after {
    content: "large"; } }

@media screen and (min-width: 1680px) {
  body:after {
    content: "hd"; } }

/* 
 *  COLORS 
----------------------------------------------------------------------*/
/* 
 *  FONTS 
---------------------------------------------------------------------------*/
/*
    2. tools
    Public mixins / functions
    ========================================================================== */
/*
    3. generic
    "Normalize", Purify, etc.
    ========================================================================== */
/* ==========================================================================
   purify / generic
   @author Jean-François Leblanc
   
   - document

   ========================================================================== */
/*
  document
  1. Inherit box-sizing which is set to border-box on :root element to prevent width/padding issues.
  2. Change the default font family in all browsers.
  3. Set a default font-size for root in all browsers.
  4. Set a fixed line height in all browsers.
  5. Prevent adjustments of font size after orientation changes on Windows Phone/IOS.
  6. Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
     we force a non-overlapping, non-auto-hiding scrollbar to counteract.
  7. Change the default tap highlight to be completely transparent in iOS.
  8. Set the font smoothing for osx/webkit
  9. Everything simply inherit of the box-sizing as it is easier to override
      without over specifying if required
  10. Text selection 
=============================================================================*/
:root {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

*,
*::before,
*::after {
  box-sizing: inherit; }

::selection {
  text-shadow: none; }

* {
  backface-visibility: hidden;
  margin: 0; }

/* Sections
     ========================================================================== */
/**
   * Hide scrollbar
   */
html {
  -ms-overflow-style: -ms-autohiding-scrollbar; }

/**
   * Remove the margin in all browsers.
   */
body {
  margin: 0; }

/**
   * Correct the font size and margin on `h1` elements within `section` and
   * `article` contexts in Chrome, Firefox, and Safari.
   */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0; }

a {
  text-decoration: none; }

/* Grouping content
     ========================================================================== */
/**
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
pre {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Text-level semantics
     ========================================================================== */
/**
   * Remove the gray background on active links in IE 10.
   */
a {
  background-color: transparent; }

/**
   * 1. Remove the bottom border in Chrome 57-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   */
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

/**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */
b,
strong {
  font-weight: bolder; }

/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/**
   * Add the correct font size in all browsers.
   */
small {
  font-size: 80%; }

/**
   * Prevent `sub` and `sup` elements from affecting the line height in
   * all browsers.
   */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
     ========================================================================== */
/**
   * Sets max-width: 100% since we're mostly working within grids. 
   */
img {
  max-width: 100%; }

/* Forms
     ========================================================================== */
/**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   * 3. Prevents border-radius on inputs
   */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none; }

/**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */
button,
input {
  overflow: visible; }

/**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */
button,
select {
  text-transform: none; }

/**
   * Correct the inability to style clickable types in iOS and Safari.
   */
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: none;
  -webkit-appearance: none; }

/**
   * Remove the inner border and padding in Firefox.
   */
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
   * Restore the focus styles unset by the previous rule.
   */
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
   * Correct the padding in Firefox.
   */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from `fieldset` elements in IE.
   * 3. Remove the padding so developers are not caught out when they zero out
   *    `fieldset` elements in all browsers.
   */
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  /* 3 */
  white-space: normal; }

/**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */
progress {
  vertical-align: baseline; }

/**
   * Remove the default vertical scrollbar in IE 10+.
   */
textarea {
  overflow: auto; }

/**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

/**
   * Remove the inner padding in Chrome and Safari on macOS.
   */
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

/* Interactive
     ========================================================================== */
/*
   * Add the correct display in Edge, IE 10+, and Firefox.
   */
details {
  display: block; }

/*
   * Add the correct display in all browsers.
   */
summary {
  display: list-item; }

/* Misc
     ========================================================================== */
/**
   * Add the correct display in IE 10+.
   */
template {
  display: none; }

button {
  border: none; }

ul,
ol {
  list-style: none;
  padding-left: 0; }

address {
  font-style: normal; }

table {
  border-collapse: collapse; }

textarea {
  resize: vertical; }

/*
    4. base
    Base html element styles (no classes)
    ========================================================================== */
/*
    5. objects
    Design patterns, no cosmetics, no context
    ========================================================================== */
.italic {
  font-style: italic; }

/*
    6. components
    BEM components
    ========================================================================== */
.cest-agressant .project__media__frame--1 {
  margin-top: 40px; }

.cest-agressant .project__media__frame--2 {
  margin-top: 400px;
  max-width: 85%; }

.equilibrium .project__media--gallery {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px; }
  @media screen and (min-width: 980px) {
    .equilibrium .project__media--gallery {
      grid-gap: 30px; } }

.equilibrium .project__media__frame--0 {
  grid-column: 1 / 3;
  grid-row: 1; }

.equilibrium .project__media__frame--1,
.equilibrium .project__media__frame--2 {
  grid-row: 2; }

.equilibrium .project__media__frame--1 {
  grid-column: 1 / 2;
  margin-top: 90px; }
  @media screen and (min-width: 980px) {
    .equilibrium .project__media__frame--1 {
      margin-top: 200px; } }

.equilibrium .project__media__frame--2 {
  grid-column: 2 / 3;
  margin-top: 120px; }
  @media screen and (min-width: 980px) {
    .equilibrium .project__media__frame--2 {
      margin-top: 200px; } }

.equilibrium .project__media__frame--3 {
  grid-column: 1 / 3;
  grid-row: 3;
  margin-top: 0;
  max-width: 85%; }
  @media screen and (min-width: 980px) {
    .equilibrium .project__media__frame--3 {
      grid-column: 1 / 2;
      max-width: 100%; } }

.equilibrium .project__media__frame--4 {
  grid-column: 1 / 3;
  grid-row: 4;
  margin-top: 120px;
  max-width: 90%;
  transform: translateX(25px); }
  @media screen and (min-width: 550px) {
    .equilibrium .project__media__frame--4 {
      max-width: 95%; } }
  @media screen and (min-width: 980px) {
    .equilibrium .project__media__frame--4 {
      grid-column: 2 / 3;
      grid-row: 3;
      margin-top: 40px;
      max-width: 100%; } }
  @media screen and (min-width: 1400px) {
    .equilibrium .project__media__frame--4 {
      transform: translateX(40px); } }

.equilibrium .project__media__frame--5 {
  grid-column: 1 / 3;
  grid-row: 5;
  margin-top: 250px;
  max-width: 80%; }
  @media screen and (min-width: 550px) {
    .equilibrium .project__media__frame--5 {
      max-width: 60%; } }
  @media screen and (min-width: 980px) {
    .equilibrium .project__media__frame--5 {
      grid-row: 4;
      max-width: 50%; } }
  @media screen and (min-width: 1400px) {
    .equilibrium .project__media__frame--5 {
      max-width: 40%; } }

.flyers .project__media--gallery {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px; }
  @media screen and (min-width: 550px) {
    .flyers .project__media--gallery {
      grid-gap: 20px; } }
  @media screen and (min-width: 768px) {
    .flyers .project__media--gallery {
      grid-gap: 30px; } }

.flyers .project__media__frame--0 {
  grid-column: 1 / 3;
  grid-row: 1;
  max-width: 90%; }
  @media screen and (min-width: 768px) {
    .flyers .project__media__frame--0 {
      max-width: 80%; } }

.flyers .project__media__frame--1 {
  grid-column: 1 / 2;
  grid-row: 2; }

.flyers .project__media__frame--2 {
  grid-column: 2 / 3;
  grid-row: 2; }

.flyers .project__media__frame--3 {
  grid-column: 1 / 2;
  grid-row: 3;
  margin-top: -10px; }

.flyers .project__media__frame--4 {
  grid-column: 2 / 3;
  grid-row: 3;
  margin-top: -10px; }

.flyers .project__media__frame--5 {
  grid-column: 1 / 3;
  grid-row: 4; }

.flyers .project__media__frame--6 {
  grid-column: 1 / 2;
  grid-row: 5;
  margin-top: 200px;
  overflow: hidden; }

.flyers .project__media__img--6 {
  margin-left: -1px; }

.flyers .project__media__frame--7 {
  grid-column: 2/ 3;
  grid-row: 5;
  margin-top: 200px;
  overflow: hidden; }

.flyers .project__media__img--7 {
  margin-left: -1px; }

.flyers .project__media__frame--8 {
  grid-column: 1 / 3;
  grid-row: 6;
  margin-top: 200px;
  max-width: 80%; }
  @media screen and (min-width: 980px) {
    .flyers .project__media__frame--8 {
      max-width: 60%; } }

.gabriel .project__media__frame {
  max-width: 100%; }
  @media screen and (min-width: 768px) {
    .gabriel .project__media__frame {
      max-width: 96%; } }

.gabriel .project__media__frame + .project__media__frame {
  margin-top: 200px; }

.lg2 .project__media--gallery {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0; }
  @media screen and (min-width: 980px) {
    .lg2 .project__media--gallery {
      grid-gap: 60px; } }

.lg2 .project__media__frame {
  margin-top: 0; }

.lg2 .project__media__frame--0,
.lg2 .project__media__frame--1,
.lg2 .project__media__frame--2,
.lg2 .project__media__frame--3,
.lg2 .project__media__frame--4,
.lg2 .project__media__frame--5 {
  grid-column: 1 / 3;
  max-width: 95%; }
  @media screen and (min-width: 980px) {
    .lg2 .project__media__frame--0,
    .lg2 .project__media__frame--1,
    .lg2 .project__media__frame--2,
    .lg2 .project__media__frame--3,
    .lg2 .project__media__frame--4,
    .lg2 .project__media__frame--5 {
      max-width: 100%; } }

.lg2 .project__media__frame--1,
.lg2 .project__media__frame--2,
.lg2 .project__media__frame--3,
.lg2 .project__media__frame--4,
.lg2 .project__media__frame--5 {
  margin-top: 60px; }
  @media screen and (min-width: 980px) {
    .lg2 .project__media__frame--1,
    .lg2 .project__media__frame--2,
    .lg2 .project__media__frame--3,
    .lg2 .project__media__frame--4,
    .lg2 .project__media__frame--5 {
      margin-top: 0; } }

.lg2 .project__media__frame--0 {
  grid-row: 1; }
  @media screen and (min-width: 980px) {
    .lg2 .project__media__frame--0 {
      grid-column: 1 / 2; } }

.lg2 .project__media__frame--1 {
  grid-row: 2; }
  @media screen and (min-width: 980px) {
    .lg2 .project__media__frame--1 {
      grid-column: 2 / 3;
      grid-row: 1; } }

.lg2 .project__media__frame--2 {
  grid-row: 3; }
  @media screen and (min-width: 980px) {
    .lg2 .project__media__frame--2 {
      grid-column: 1 / 2;
      grid-row: 2; } }

.lg2 .project__media__frame--3 {
  grid-row: 4; }
  @media screen and (min-width: 980px) {
    .lg2 .project__media__frame--3 {
      grid-column: 2 / 3;
      grid-row: 2; } }

.lg2 .project__media__frame--4 {
  grid-row: 5; }
  @media screen and (min-width: 980px) {
    .lg2 .project__media__frame--4 {
      grid-column: 1 / 2;
      grid-row: 3; } }

.lg2 .project__media__frame--5 {
  grid-row: 6; }
  @media screen and (min-width: 980px) {
    .lg2 .project__media__frame--5 {
      grid-column: 2 / 3;
      grid-row: 3; } }

.mini-putt-91 .project__media__frame--1 {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px; }
  @media screen and (min-width: 550px) {
    .mini-putt-91 .project__media__frame--1 {
      margin-top: -30px; } }
  @media screen and (min-width: 980px) {
    .mini-putt-91 .project__media__frame--1 {
      margin-top: -40px; } }

.mini-putt-91 .project__media__img--1 {
  max-height: 125px;
  max-width: 222px; }
  @media screen and (min-width: 400px) {
    .mini-putt-91 .project__media__img--1 {
      max-height: 175px;
      max-width: 311px; } }
  @media screen and (min-width: 550px) {
    .mini-putt-91 .project__media__img--1 {
      max-height: 225px;
      max-width: 400px; } }
  @media screen and (min-width: 768px) {
    .mini-putt-91 .project__media__img--1 {
      max-height: 200px;
      max-width: 356px; } }
  @media screen and (min-width: 980px) {
    .mini-putt-91 .project__media__img--1 {
      max-height: none;
      max-width: none; } }

.mini-putt-91 .project__media__frame--2 {
  margin-top: 150px; }

.mini-putt-91 .project__media__frame--3 {
  margin-top: 300px;
  max-width: 90%; }
  @media screen and (min-width: 400px) {
    .mini-putt-91 .project__media__frame--3 {
      max-width: 80%; } }
  @media screen and (min-width: 768px) {
    .mini-putt-91 .project__media__frame--3 {
      max-width: 90%; } }
  @media screen and (min-width: 980px) {
    .mini-putt-91 .project__media__frame--3 {
      max-width: 100%; } }

.ombrelle .project__media__frame--2 {
  margin-top: 150px; }

.ombrelle .project__media__frame--3 {
  margin-top: 200px;
  max-width: 90%; }
  @media screen and (min-width: 980px) {
    .ombrelle .project__media__frame--3 {
      max-width: 80%; } }

.ombrelle .project__media__frame--4 {
  margin-top: 300px; }

.this-portfolio .project__media__frame--0 {
  max-width: 300px; }

.this-portfolio .project__media__frame--1 {
  margin-top: 4000px;
  max-width: 300px;
  overflow: hidden; }

.this-portfolio .project__media__img--1 {
  transform: translateX(-10px); }

.pullman .project__media--gallery {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0; }
  @media screen and (min-width: 980px) {
    .pullman .project__media--gallery {
      grid-gap: 60px; } }

.pullman .project__media__frame {
  margin-top: 0; }

.pullman .project__media__frame--0,
.pullman .project__media__frame--1,
.pullman .project__media__frame--2,
.pullman .project__media__frame--3,
.pullman .project__media__frame--4,
.pullman .project__media__frame--5 {
  grid-column: 1 / 3;
  max-width: 95%; }
  @media screen and (min-width: 980px) {
    .pullman .project__media__frame--0,
    .pullman .project__media__frame--1,
    .pullman .project__media__frame--2,
    .pullman .project__media__frame--3,
    .pullman .project__media__frame--4,
    .pullman .project__media__frame--5 {
      max-width: 100%; } }

.pullman .project__media__frame--1,
.pullman .project__media__frame--2,
.pullman .project__media__frame--3,
.pullman .project__media__frame--4,
.pullman .project__media__frame--5 {
  margin-top: 60px; }
  @media screen and (min-width: 980px) {
    .pullman .project__media__frame--1,
    .pullman .project__media__frame--2,
    .pullman .project__media__frame--3,
    .pullman .project__media__frame--4,
    .pullman .project__media__frame--5 {
      margin-top: 0; } }

.pullman .project__media__frame--0 {
  grid-row: 1; }
  @media screen and (min-width: 980px) {
    .pullman .project__media__frame--0 {
      grid-column: 1 / 2; } }

.pullman .project__media__frame--1 {
  grid-row: 2; }
  @media screen and (min-width: 980px) {
    .pullman .project__media__frame--1 {
      grid-column: 2 / 2;
      grid-row: 1; } }

.pullman .project__media__frame--2 {
  grid-row: 3; }
  @media screen and (min-width: 980px) {
    .pullman .project__media__frame--2 {
      grid-column: 1 / 2;
      grid-row: 2; } }

.pullman .project__media__frame--3 {
  grid-row: 4; }
  @media screen and (min-width: 980px) {
    .pullman .project__media__frame--3 {
      grid-column: 2 / 3;
      grid-row: 2; } }

.pullman .project__media__frame--4 {
  grid-row: 5; }
  @media screen and (min-width: 980px) {
    .pullman .project__media__frame--4 {
      grid-column: 1 / 2;
      grid-row: 3; } }

.pullman .project__media__frame--5 {
  grid-row: 6; }
  @media screen and (min-width: 980px) {
    .pullman .project__media__frame--5 {
      grid-column: 2 / 3;
      grid-row: 3; } }

.tes-pas-game .project__media--gallery {
  grid-template-columns: repeat(16, 1fr);
  grid-gap: 10px; }

.tes-pas-game .project__media__frame--0,
.tes-pas-game .project__media__frame--1,
.tes-pas-game .project__media__frame--5 {
  grid-column: 1 / 17; }

.tes-pas-game .project__media__frame--0 {
  grid-row: 1; }

.tes-pas-game .project__media__frame--1 {
  grid-row: 2;
  margin-top: 40px; }

.tes-pas-game .project__media__frame--2 {
  grid-column: 1 / 12;
  grid-row: 3;
  margin-top: 85px; }
  @media screen and (min-width: 550px) {
    .tes-pas-game .project__media__frame--2 {
      grid-column: 1 / 10; } }

.tes-pas-game .project__media__frame--3 {
  grid-column: 3 / 17;
  grid-row: 4;
  margin-top: 5px; }
  @media screen and (min-width: 550px) {
    .tes-pas-game .project__media__frame--3 {
      grid-row: 3;
      grid-column: 9 / 17;
      margin-top: 75px; } }

.tes-pas-game .project__media__frame--4 {
  grid-column: 2 / 17;
  grid-row: 5;
  margin-top: 300px; }
  @media screen and (min-width: 550px) {
    .tes-pas-game .project__media__frame--4 {
      grid-row: 4; } }

.tes-pas-game .project__media__frame--5 {
  grid-row: 6;
  margin-top: 350px; }
  @media screen and (min-width: 550px) {
    .tes-pas-game .project__media__frame--5 {
      grid-row: 5; } }

.space-square .project__media--gallery {
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 0; }
  @media screen and (min-width: 768px) {
    .space-square .project__media--gallery {
      grid-gap: 50px; } }

.space-square .project__media__frame--0,
.space-square .project__media__frame--1,
.space-square .project__media__frame--4 {
  grid-column: 1 / 9; }

.space-square .project__media__frame--2 {
  grid-column: 1 / 9;
  max-width: 60%; }
  @media screen and (min-width: 980px) {
    .space-square .project__media__frame--2 {
      max-width: 100%;
      grid-column: 1 / 4; } }

.space-square .project__media__frame--3 {
  grid-column: 1 / 9;
  margin-top: 125px; }
  @media screen and (min-width: 980px) {
    .space-square .project__media__frame--3 {
      grid-column: 4 / 9; } }

.space-square .project__media__frame--4 {
  margin-top: 300px;
  max-width: 80%; }
  @media screen and (min-width: 550px) {
    .space-square .project__media__frame--4 {
      max-width: 70%; } }
  @media screen and (min-width: 980px) {
    .space-square .project__media__frame--4 {
      max-width: 60%; } }
  @media screen and (min-width: 1100px) {
    .space-square .project__media__frame--4 {
      max-width: 40%; } }

.think-tank .project__media--gallery {
  grid-template-columns: repeat(16, 1fr);
  grid-gap: 10px; }

.think-tank .project__media__frame--0 {
  grid-column: 1 / 17; }

.think-tank .project__media__frame--1 {
  grid-column: 1 / 17;
  margin-top: 100px; }
  @media screen and (min-width: 550px) {
    .think-tank .project__media__frame--1 {
      grid-column: 1 / 8;
      margin-top: 160px; } }

.think-tank .project__media__frame--2,
.think-tank .project__media__frame--3 {
  height: fit-content;
  overflow: hidden; }

.think-tank .project__media__img--2,
.think-tank .project__media__img--3 {
  transform: translateY(11px); }

.think-tank .project__media__frame--2 {
  grid-column: 1 / 7;
  margin-top: 150px; }
  @media screen and (min-width: 550px) {
    .think-tank .project__media__frame--2 {
      grid-column: 8 / 12; } }

.think-tank .project__media__frame--3 {
  grid-column: 8 / 16;
  margin-top: 225px; }
  @media screen and (min-width: 550px) {
    .think-tank .project__media__frame--3 {
      grid-column: 12 / 17; } }

.ward-associes .project__media--gallery {
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 0; }
  @media screen and (min-width: 980px) {
    .ward-associes .project__media--gallery {
      grid-gap: 60px; } }

.ward-associes .project__media__frame--0,
.ward-associes .project__media__frame--1 {
  grid-column: 1 / 9;
  max-width: 95%; }
  @media screen and (min-width: 980px) {
    .ward-associes .project__media__frame--0,
    .ward-associes .project__media__frame--1 {
      max-width: 100%; } }

.ward-associes .project__media__frame {
  margin-top: 0; }

.ward-associes .project__media__frame--0 {
  grid-row: 1; }
  @media screen and (min-width: 980px) {
    .ward-associes .project__media__frame--0 {
      grid-column: 1 / 6;
      grid-row: 1; } }

.ward-associes .project__media__frame--1 {
  grid-row: 2;
  margin-top: 60px; }
  @media screen and (min-width: 980px) {
    .ward-associes .project__media__frame--1 {
      grid-column: 6 / 9;
      grid-row: 1;
      margin-top: 0; } }

.project__media--video {
  background-color: #161616;
  height: 360px;
  overflow: hidden;
  width: 640px; }
  .tes-pas-game .project__media--video {
    height: 359px; }

/*
    7. utilities
    Overrides, helpers, utilities
    ========================================================================== */
.home {
  background-color: #161616;
  color: white;
  cursor: url(../../assets/svg/cursor_white.svg) 10 2, auto;
  height: 100vh;
  position: relative;
  transition: opacity .3s linear;
  width: 100vw; }

.home__square {
  border: 1px solid white;
  pointer-events: none;
  position: absolute; }

.home__title {
  font-family: "Heebo", sans-serif;
  right: 0;
  opacity: .9;
  position: absolute;
  top: 66vh;
  width: 80%; }

.home__name {
  color: white;
  font-size: 45px;
  font-weight: 700;
  margin: 0 30px 0 0; }

.home__portfolio {
  font-size: 35px;
  font-weight: 300;
  margin: 0; }

.home__line {
  background-color: white;
  height: 2px;
  margin: 5px 0 15px;
  width: 100%; }

.home__portrayal {
  font-size: 25px;
  font-weight: 300;
  margin: 0; }
