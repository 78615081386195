.ward-associes {

    .project__media--gallery {
        grid-template-columns: repeat(8, 1fr);
        grid-gap: 0;
        @media #{$mqTablet} { 
            grid-gap: 60px;
        }
    }

    .project__media__frame--0,
    .project__media__frame--1 {
        grid-column: 1 / 9;
        max-width: 95%;
        @media #{$mqTablet} { 
            max-width: 100%;
        }
    }

    .project__media__frame {
        margin-top: 0;
    }

    .project__media__frame--0 {
        grid-row: 1;
        @media #{$mqTablet} { 
            grid-column: 1 / 6;
            grid-row: 1;
        }
    }

    .project__media__frame--1 {
        grid-row: 2;
        margin-top: 60px;
        @media #{$mqTablet} { 
            grid-column: 6 / 9;
            grid-row: 1;
            margin-top: 0;
        }
    }
}