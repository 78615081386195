.gabriel {


    .project__media__frame {
        max-width: 100%;
        @media #{$mqTabletP} {
            max-width: 96%;
        }
    }

    .project__media__frame + .project__media__frame {
        margin-top: 200px;
    }
}