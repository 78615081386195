.flyers {

    .project__media--gallery {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        @media #{$mqMobile} {
            grid-gap: 20px;
        }
        @media #{$mqTabletP} {
            grid-gap: 30px;
        }
    }

    .project__media__frame--0 {
        grid-column: 1 / 3;
        grid-row: 1; 
        max-width: 90%;
        @media #{$mqTabletP} {
            max-width: 80%;
        }
    }

    .project__media__frame--1 {
        grid-column: 1 / 2;
        grid-row: 2; 
    }

    .project__media__frame--2 {
        grid-column: 2 / 3;
        grid-row: 2; 
    }

    .project__media__frame--3 {
        grid-column: 1 / 2;
        grid-row: 3; 
        margin-top: -10px;
    }
    
    .project__media__frame--4 {
        grid-column: 2 / 3;
        grid-row: 3; 
        margin-top: -10px;
    }

    .project__media__frame--5 {
        grid-column: 1 / 3;
        grid-row: 4; 
    }

    .project__media__frame--6 {
        grid-column: 1 / 2;
        grid-row: 5; 
        margin-top: 200px;
        overflow: hidden;
    }
    
    .project__media__img--6 {
        margin-left: -1px;
    }
    
    .project__media__frame--7 {
        grid-column: 2/ 3;
        grid-row: 5; 
        margin-top: 200px;
        overflow: hidden;
    }

    .project__media__img--7 {
        margin-left: -1px;
    }

    .project__media__frame--8 {
        grid-column: 1 / 3;
        grid-row: 6; 
        margin-top: 200px;
        max-width: 80%;
        @media #{$mqTablet} {
            max-width: 60%;
        }
    }
}