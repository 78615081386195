.tes-pas-game {

    .project__media--gallery {
        grid-template-columns: repeat(16, 1fr);
        grid-gap: 10px;
    }

    .project__media__frame--0,
    .project__media__frame--1,
    .project__media__frame--5 {
        grid-column: 1 / 17;
    }

    .project__media__frame--0 {
        grid-row: 1; 
    }

    .project__media__frame--1 {
        grid-row: 2; 
        margin-top: 40px;
    }

    .project__media__frame--2 {
        grid-column: 1 / 12;
        grid-row: 3; 
        margin-top: 85px;
        @media #{$mqMobile} {
            grid-column: 1 / 10;
        }
    }
    
    .project__media__frame--3 {
        grid-column: 3 / 17;
        grid-row: 4; 
        margin-top: 5px;
        @media #{$mqMobile} {
            grid-row: 3; 
            grid-column: 9 / 17;
            margin-top: 75px;
        }
    }
    
    .project__media__frame--4 {
        grid-column: 2 / 17;
        grid-row: 5; 
        margin-top: 300px;
        @media #{$mqMobile} {
            grid-row: 4; 
        }
    }
    
    .project__media__frame--5 {
        grid-row: 6; 
        margin-top: 350px;
        @media #{$mqMobile} {
            grid-row: 5; 
        }
    }
}