.lg2 {

    .project__media--gallery {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0;
        @media #{$mqTablet} { 
            grid-gap: 60px;
        }
    }

    .project__media__frame {
        margin-top: 0;
    }

    .project__media__frame--0,
    .project__media__frame--1,
    .project__media__frame--2,
    .project__media__frame--3,
    .project__media__frame--4,
    .project__media__frame--5 {
        grid-column: 1 / 3;
        max-width: 95%;
        @media #{$mqTablet} { 
            max-width: 100%;
        }
    }

    .project__media__frame--1,
    .project__media__frame--2,
    .project__media__frame--3,
    .project__media__frame--4,
    .project__media__frame--5 {
        margin-top: 60px;
        @media #{$mqTablet} { 
            margin-top: 0;
        }
    }

    .project__media__frame--0 {
        grid-row: 1;
        @media #{$mqTablet} { 
            grid-column: 1 / 2;
        }
    }

    .project__media__frame--1 {
        grid-row: 2;
        @media #{$mqTablet} { 
            grid-column: 2 / 3;
            grid-row: 1;
        }
    }

    .project__media__frame--2 {
        grid-row: 3;
        @media #{$mqTablet} { 
            grid-column: 1 / 2;
            grid-row: 2;
        }
    }

    .project__media__frame--3 {
        grid-row: 4;
        @media #{$mqTablet} { 
            grid-column: 2 / 3;
            grid-row: 2;
        }
    }

    .project__media__frame--4 {
        grid-row: 5;
        @media #{$mqTablet} { 
            grid-column: 1 / 2;
            grid-row: 3;
        }
    }

    .project__media__frame--5 {
        grid-row: 6;
        @media #{$mqTablet} { 
            grid-column: 2 / 3;
            grid-row: 3;
        }
    }
}