/* 
 *  BREAKPOINTS 
----------------------------------------------------------------------*/ 

/*
    * 4. Breakpoints
    ****************************************************************/
    
    $bpMobileS: 400px;
    $bpMobile: 550px;
    $bpTabletP: 768px;
    $bpTablet: 980px;
    //$bpDesktop: 1024px;
    $bpDesktop: 1100px;
    $bpLarge: 1400px;
    $bpHd: 1680px;

    $bpTabletEnd: ($bpDesktop - 1);


/*
    * 5. Media queries
    ****************************************************************/

    $mqMobileS: 'screen and (min-width: #{$bpMobileS})';
    $mqMobile: 'screen and (min-width: #{$bpMobile})';
    $mqTabletP: 'screen and (min-width: #{$bpTabletP})';
    $mqTablet: 'screen and (min-width: #{$bpTablet})';
    $mqDesktop: 'screen and (min-width: #{$bpDesktop})';
    $mqLarge: 'screen and (min-width: #{$bpLarge})';
    $mqHd: 'screen and (min-width: #{$bpHd})';

    // Add pseudo element to body to be able to sync breakpoint with JS
    body:after {
        content: "none";
        display: none; /* Prevent from displaying. */
    }
    @media #{$mqMobileS} {
        body:after {
            content: "mobiles";
        }
    }
    @media #{$mqMobile} {
        body:after {
            content: "mobile";
        }
    }
    @media #{$mqTabletP} {
        body:after {
            content: "tabletp";
        }
    }
    @media #{$mqTablet} {
        body:after {
            content: "tablet";
        }
    }
    @media #{$mqDesktop} {
        body:after {
            content: "desktop";
        }
    }
    @media #{$mqLarge} {
        body:after {
            content: "large";
        }
    }
    @media #{$mqHd} {
        body:after {
            content: "hd";
        }
    }
    
    
    
    