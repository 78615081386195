.this-portfolio {

    .project__media__frame--0 {
        max-width: 300px;
    }

    .project__media__frame--1 {
        margin-top: 4000px;
        max-width: 300px;
        overflow: hidden;
    }

    .project__media__img--1 {
        transform: translateX(-10px);
    }
}