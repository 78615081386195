@mixin links($bottomUnderline: -4px, $bottomUnderlineHover: 6px, $widthUnderline: 80%, $heightUnderline: 2px, $topBackgroundHover:5px, $heightBackgroundHover: 36px) {  
  
  &:after {
    background-color: $colorBlue;
    bottom: $bottomUnderline; 
    content: '';
    height: $heightUnderline;
    left: 0;
    pointer-events: none;
    position: absolute;
    width: $widthUnderline;
  }
  

  a {
    color: $colorBlue;
    cursor: url(../../assets/svg/cursor_white.svg) 10 2, auto;
    padding: 10px 10px 10px 0;
    position: relative;
    transition: color .3s ease;
    width: fit-content;
    &:before {
      background-color: $colorBlack;
      top: $topBackgroundHover; 
      content: '';
      height: $heightBackgroundHover;
      position: absolute;
      right: 10px;
      transition: width .3s;
      width: 0;
      z-index: -1;
    }
    &:after {
      background-color: $colorYellow;
      bottom: $bottomUnderlineHover; 
      content: '';
      height: $heightUnderline;
      right: 10px;
      position: absolute;
      transition: width .3s;
      width: 0;
      z-index: 5;
    }
    
    &:hover {
      color: $colorYellow !important;
      &:before,
      &:after {
          left: 0;
          width: calc(100% - 10px);
      }
    }
  }
}
