/* 
 *  FONTS 
---------------------------------------------------------------------------*/ 

@import url(https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400);
@import url(https://fonts.googleapis.com/css?family=Inconsolata);

$fontHeebo : 'Heebo', sans-serif;
$font-description : 'Source Sans Pro', sans-serif;
$fontCode : 'Inconsolata', monospace;


