.mini-putt-91 {

    .project__media__frame--1 {
        display: flex;
        justify-content: flex-end;
        margin-top: -20px;
        @media #{$mqMobile} {
            margin-top: -30px;
        }
        @media #{$mqTablet} {
            margin-top: -40px;
        }
    }

    .project__media__img--1 {
        max-height: 125px;
        max-width: 222px;
        @media #{$mqMobileS} {
            max-height: 175px;
            max-width: 311px;
        }
        @media #{$mqMobile} { 
            max-height: 225px;
            max-width: 400px;
        }
        @media #{$mqTabletP} {
            max-height: 200px;
            max-width: 356px;
        }
        @media #{$mqTablet} {
            max-height: none;
            max-width: none;
        }
    }

    .project__media__frame--2 {
        margin-top: 150px;
    }

    .project__media__frame--3 {
        margin-top: 300px;
        max-width: 90%;
        @media #{$mqMobileS} {
            max-width: 80%;
        }
        @media #{$mqTabletP} {
            max-width: 90%;
        }
        @media #{$mqTablet} {
            max-width: 100%;
        }
    }
}