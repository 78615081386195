/* ==========================================================================
   purify / generic
   @author Jean-François Leblanc
   
   - document

   ========================================================================== */

/*
  document
  1. Inherit box-sizing which is set to border-box on :root element to prevent width/padding issues.
  2. Change the default font family in all browsers.
  3. Set a default font-size for root in all browsers.
  4. Set a fixed line height in all browsers.
  5. Prevent adjustments of font size after orientation changes on Windows Phone/IOS.
  6. Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
     we force a non-overlapping, non-auto-hiding scrollbar to counteract.
  7. Change the default tap highlight to be completely transparent in iOS.
  8. Set the font smoothing for osx/webkit
  9. Everything simply inherit of the box-sizing as it is easier to override
      without over specifying if required
  10. Text selection 
=============================================================================*/

:root {
    box-sizing: border-box; // 1
    -webkit-text-size-adjust: 100%; // 5
    -ms-text-size-adjust: 100%; // 5
    -moz-osx-font-smoothing: grayscale; // 8
    -webkit-font-smoothing: antialiased; // 8
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit; // 9
  }
  
  ::selection {
    text-shadow: none; // 10
  }

* {
    backface-visibility: hidden;
    margin: 0;
}

  
  /* Sections
     ========================================================================== */
  
  /**
   * Hide scrollbar
   */
  
  html {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  
  /**
   * Remove the margin in all browsers.
   */
  
  body {
    margin: 0;
  }
  
  /**
   * Correct the font size and margin on `h1` elements within `section` and
   * `article` contexts in Chrome, Firefox, and Safari.
   */
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    margin: 0;
  }
  
  a {
    text-decoration: none;
  }
  
  /* Grouping content
     ========================================================================== */
  
  /**
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */
  
  hr {
    box-sizing: content-box; // 1
    height: 0; // 1
    overflow: visible; // 2
  }
  
  /**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
  
  pre {
    font-family: monospace, monospace; // 1
    font-size: 1em; // 2
  }
  
  /* Text-level semantics
     ========================================================================== */
  
  /**
   * Remove the gray background on active links in IE 10.
   */
  
  a {
    background-color: transparent;
  }
  
  /**
   * 1. Remove the bottom border in Chrome 57-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   */
  
  abbr[title] {
    border-bottom: none; // 1
    text-decoration: underline; // 2
    text-decoration: underline dotted; // 2
  }
  
  /**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */
  
  b,
  strong {
    font-weight: bolder;
  }
  
  /**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
  
  code,
  kbd,
  samp {
    font-family: monospace, monospace; // 1
    font-size: 1em; // 2
  }
  
  /**
   * Add the correct font size in all browsers.
   */
  
  small {
    font-size: 80%;
  }
  
  /**
   * Prevent `sub` and `sup` elements from affecting the line height in
   * all browsers.
   */
  
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  
  sub {
    bottom: -0.25em;
  }
  
  sup {
    top: -0.5em;
  }
  
  /* Embedded content
     ========================================================================== */
  
  /**
   * Sets max-width: 100% since we're mostly working within grids. 
   */
  
  img {
    max-width: 100%;
  }
  
  /* Forms
     ========================================================================== */
  
  /**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   * 3. Prevents border-radius on inputs
   */
  
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; // 1
    font-size: 100%; // 1
    line-height: 1.15; // 1
    margin: 0; // 2
    -moz-appearance: none; // 3
    -webkit-appearance: none; // 3
  }
  
  /**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */
  
  button,
  input {
    // 1
    overflow: visible;
  }
  
  /**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */
  
  button,
  select {
    // 1
    text-transform: none;
  }
  
  /**
   * Correct the inability to style clickable types in iOS and Safari.
   */
  
  // button,
  // [type='button'],
  // [type='reset'],
  // [type='submit'] {
  //   -webkit-appearance: button;
  // }
  
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  
  /**
   * Remove the inner border and padding in Firefox.
   */
  
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  
  /**
   * Restore the focus styles unset by the previous rule.
   */
  
  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  
  /**
   * Correct the padding in Firefox.
   */
  
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }
  
  /**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from `fieldset` elements in IE.
   * 3. Remove the padding so developers are not caught out when they zero out
   *    `fieldset` elements in all browsers.
   */
  
  legend {
    box-sizing: border-box; // 1
    color: inherit; // 2
    display: table; // 1
    max-width: 100%; // 1
    padding: 0; /* 3 */
    white-space: normal; // 1
  }
  
  /**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */
  
  progress {
    vertical-align: baseline;
  }
  
  /**
   * Remove the default vertical scrollbar in IE 10+.
   */
  
  textarea {
    overflow: auto;
  }
  
  /**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */
  
  [type='checkbox'],
  [type='radio'] {
    box-sizing: border-box; // 1
    padding: 0; // 2
  }
  
  /**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */
  
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  
  /**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */
  
  [type='search'] {
    -webkit-appearance: textfield; // 1
    outline-offset: -2px; // 2
  }
  
  /**
   * Remove the inner padding in Chrome and Safari on macOS.
   */
  
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  
  /**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */
  
  ::-webkit-file-upload-button {
    -webkit-appearance: button; // 1
    font: inherit; // 2
  }
  
  /* Interactive
     ========================================================================== */
  
  /*
   * Add the correct display in Edge, IE 10+, and Firefox.
   */
  
  details {
    display: block;
  }
  
  /*
   * Add the correct display in all browsers.
   */
  
  summary {
    display: list-item;
  }
  
  /* Misc
     ========================================================================== */
  
  /**
   * Add the correct display in IE 10+.
   */
  
  template {
    display: none;
  }
  
  button {
    border: none;
  }
  
  ul,
  ol {
    list-style: none;
    padding-left: 0;
  }
  
  address {
    font-style: normal;
  }
  
  table {
    border-collapse: collapse;
  }
  
  textarea {
    resize: vertical; // allow only vertical resizing
  }
  