.think-tank {

    .project__media--gallery {
        grid-template-columns: repeat(16, 1fr);
        grid-gap: 10px;
    }

    .project__media__frame--0 {
        grid-column: 1 / 17;
    }

    .project__media__frame--1 {
        grid-column: 1 / 17;
        margin-top: 100px;
        
        @media #{$mqMobile} {
            grid-column: 1 / 8;
            margin-top: 160px;

        }
    }

    .project__media__frame--2,
    .project__media__frame--3 {
        height: fit-content;
        overflow: hidden;
    }


    .project__media__img--2,
    .project__media__img--3 {
        transform: translateY(11px);

    }

    .project__media__frame--2 {
        grid-column: 1 / 7;
        margin-top: 150px;
        
        @media #{$mqMobile} {
            grid-column: 8 / 12;

        }
    }

    .project__media__frame--3 {
        grid-column: 8 / 16;
        margin-top: 225px;

        @media #{$mqMobile} {
            grid-column: 12 / 17;
        }
    }
}