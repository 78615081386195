.cest-agressant {
    
    .project__media__frame--1 {
        margin-top: 40px;
    }

    .project__media__frame--2 {
        margin-top: 400px;
        max-width: 85%;
    }
}