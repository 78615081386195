.ombrelle {

    .project__media__frame--2 {
        margin-top: 150px;
    }
 
    .project__media__frame--3 {
        margin-top: 200px;
        max-width: 90%;
        @media #{$mqTablet} {
            max-width: 80%;
        }
    }

    .project__media__frame--4 {
        margin-top: 300px;
    }
}