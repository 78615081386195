.equilibrium {

    .project__media--gallery {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        @media #{$mqTablet} {
            grid-gap: 30px;
        }
    }

    .project__media__frame--0 {
        grid-column: 1 / 3;
        grid-row: 1; 
    }
    
    .project__media__frame--1,
    .project__media__frame--2 {
        grid-row: 2; 
    }

    .project__media__frame--1 {
        grid-column: 1 / 2;
        margin-top: 90px;
        @media #{$mqTablet} {
            margin-top: 200px;
        }
    }
    
    .project__media__frame--2 {
        grid-column: 2 / 3;
        margin-top: 120px;
        @media #{$mqTablet} {
            margin-top: 200px;
        }
    }

    .project__media__frame--3 {
        grid-column: 1 / 3;
        grid-row: 3;
        margin-top: 0;
        max-width: 85%;
        @media #{$mqTablet} {
            grid-column: 1 / 2;
            max-width: 100%;
        }   
    }
    
    .project__media__frame--4 {
        grid-column: 1 / 3;
        grid-row: 4; 
        margin-top: 120px;
        max-width: 90%;
        transform: translateX(25px);
        @media #{$mqMobile} {
            max-width: 95%;
        }
        @media #{$mqTablet} {
            grid-column: 2 / 3;
            grid-row: 3; 
            margin-top: 40px;
            max-width: 100%;
        }  
        @media #{$mqLarge} {
            transform: translateX(40px);
        }
    }
    
    .project__media__frame--5 {
        grid-column: 1 / 3;
        grid-row: 5; 
        margin-top: 250px;
        max-width: 80%;
        @media #{$mqMobile} {
            max-width: 60%;
        }
        @media #{$mqTablet} {
            grid-row: 4; 
            max-width: 50%;
        }
        @media #{$mqLarge} {
            max-width: 40%;
        }
    }
}