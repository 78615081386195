.space-square {

    .project__media--gallery {
        grid-template-columns: repeat(8, 1fr);
        grid-gap: 0;
        @media #{$mqTabletP} {
            grid-gap: 50px;
        }
    }

    .project__media__frame--0,
    .project__media__frame--1,
    .project__media__frame--4 {
        grid-column: 1 / 9;
    }

    .project__media__frame--2 {
        grid-column: 1 / 9;
        max-width: 60%;
        @media #{$mqTablet} {
            max-width: 100%;
            grid-column: 1 / 4;
        }
    }
    
    .project__media__frame--3 {
        grid-column: 1 / 9;
        margin-top: 125px;
        @media #{$mqTablet} {
            grid-column: 4 / 9;
        }
    }
    .project__media__frame--4 {
        margin-top: 300px;
        max-width: 80%;
        @media #{$mqMobile} {
            max-width: 70%;
        }
        @media #{$mqTablet} {
            max-width: 60%;
        }
        @media #{$mqDesktop} {
            max-width: 40%;
        }

    }
}